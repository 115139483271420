import { Component, OnInit } from '@angular/core';
import { AuthService } from '../service';
import {environment} from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { StorageService } from '../service/storage.service';

@Component({
  selector: 'app-user-page',
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.scss']
})
export class UserPageComponent implements OnInit {

  name;
  email;
  phoneNumber;
  password;
UserURL: string = environment.serverUrl + '/user-profile'

  constructor(private authService: AuthService,
    
  ) { }

  ngOnInit(): void {
   
    this.authService.fullPayloadData.subscribe((payload) => {
    
        this.name = payload['cognito:username'];
        this.email = payload?.email;
        this.phoneNumber = payload?.phone_number;
    })
  }

}
