import { ChangeDetectorRef, Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DropDownService } from '../../../service/drop-down.service';
import { AnalysisDetails, DropDown, SearchResult, Templates, AnalysisDetailsSLR } from '../../../model/analysis-details.model';
import { TemplateService } from '../../../service/template.service';
import { SharedService } from '../../../layouts/shared-service';
import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';
import { UploadService } from '../../../service/upload.service';
import { StorageService } from '../../../service/storage.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-small-large-report',
  templateUrl: './small-large-report.component.html',
  styleUrls: ['./small-large-report.component.scss']
})
export class SmallLargeReportComponent implements OnChanges {

  @Input() analysistype: string;
  @Input() editvalues: any;
  @Output() formvalue = new EventEmitter<any>();
  pageTitle = 'Analyze Report/Data';

  analysisFormGroup: FormGroup;
  maxDate;
  // lts80_dialog: FormControl = new FormControl();
  analysisDetails: AnalysisDetails;
  subtype: AnalysisDetails;
  tableNamesList: any[];
  tableNames: any;
  showTable = false;
  countTable = false;
  post: any;
  tableParamValue = [];
  GenTablesList = [];
  ValTablesList = [];
  SumTablesList = [];
  Smparameterlist = [];
  NewSmparameterlist = [];
  Lmparameterlist = [];
  NewLmparameterlist = [];
  selectedValTables = [];
  selectedAssayTables = [];
  selectedGeneralTables = [];
  decimalNumberPattern = '^[0-9]+(\.[0-9]+)?$';
  disabledValue;
  tempdataerror;
  tempdataerrorvalue:boolean=false;


  constructor(
    private formBuilder: FormBuilder,
    private _sharedService: SharedService,
    private uploadService: UploadService,
    private dropDownService: DropDownService,
    private templateService: TemplateService,
    private storageService: StorageService,
    private datePipe: DatePipe,
    private ref: ChangeDetectorRef
  ) {

    this._sharedService.emitChange(this.pageTitle);
    uploadService.disabledValue.subscribe(res => {
      this.disabledValue = res;

    })

  }


  ngOnChanges(changes: SimpleChanges) {

    if ('analysistype' in changes) {
      if (changes.analysistype.currentValue) {
        this.analysistype = changes.analysistype.currentValue;
        this.Smparameterlist = [];
        this.Lmparameterlist = [];
      }

    }

    this.createForm();
    this.getData();
  }


  createForm() {

    this.analysisFormGroup = this.formBuilder.group({
      analysisType: [this.analysistype],
      analysisSubtype: [null],
      projectCode: [''],
      // projectCodeMV: [null],
      regressionModel: [null],
      lloq: [null],
      uloq: [null],
      re_type: [true],
      re_value: [null],
      lts80_dialog: [null, [Validators.pattern(this.decimalNumberPattern)]],
      extraction_dialog: [null, [Validators.pattern(this.decimalNumberPattern)]],
      benchtop_dialog: [null, [Validators.pattern(this.decimalNumberPattern)]],
      freeze_thaw_dialog: [null, [Validators.pattern(this.decimalNumberPattern)]],
      multipleAnalytes: ['False'],
      first_patient_dialog: [null],
      sampleMatrix: ['', [Validators.pattern('^[a-zA-Z ]*$')]],
      antiCoagulant: ['', [Validators.pattern('^[A-Za-z0-9? ,_-]+$')]],
      species: ['', [Validators.pattern('^[a-zA-Z ]*$')]],
      samplePrep: [null],
      sampleDate: ['', [Validators.max(this.maxDate)]],
      analyteNames: this.formBuilder.array([this.addAnalyteRows()]),
      // selectedGeneralTableIds: [null],
      // selectedAssayTableIds: [null],
      // selectedValidationTableIds: [null],
      tableParamValue: [null],
      files: [null],
      file_path: [null],
      analyteName: [null],
      thresholdLayout: ['False'],
      // selectedGeneralTableName: [null],
      // selectedAssayTableName: [null],
      // selectedValidationTableName: [null],
    });
    // this.analysisFormGroup.get('multipleAnalytes')?.disable();
    this.ref.detectChanges();


    if (this.editvalues !== undefined) {
      const SLRvalue: AnalysisDetailsSLR = new AnalysisDetailsSLR()
      SLRvalue.analysisType = this.analysistype;
      SLRvalue.analysisSubtype = this.editvalues.analysisSubtype;
      SLRvalue.projectCode = this.editvalues.projectCode;
      // SLRvalue.projectCodeMV = this.editvalues.projectCodeMV;
      SLRvalue.regressionModel = this.editvalues.regressionModel;
      SLRvalue.lloq = this.editvalues.lloq;
      SLRvalue.uloq = this.editvalues.uloq;
      SLRvalue.re_type = this.editvalues.re_type;
      SLRvalue.re_value = this.editvalues.re_value;
      SLRvalue.lts80_dialog = this.editvalues.lts80_dialog;
      SLRvalue.extraction_dialog = this.editvalues.extraction_dialog;
      SLRvalue.benchtop_dialog = this.editvalues.benchtop_dialog;
      SLRvalue.freeze_thaw_dialog = this.editvalues.freeze_thaw_dialog;
      SLRvalue.multipleAnalytes = this.editvalues.multipleAnalytes;
      SLRvalue.first_patient_dialog = this.editvalues.first_patient_dialog;
      SLRvalue.sampleMatrix = this.editvalues.sampleMatrix;
      SLRvalue.antiCoagulant = this.editvalues.antiCoagulant;
      SLRvalue.species = this.editvalues.species;
      SLRvalue.samplePrep = this.editvalues.samplePrep;
      SLRvalue.sampleDate = this.editvalues.sampleDate;
      SLRvalue.analyteNames = this.editvalues.analyteNames;
      // SLRvalue.selectedGeneralTableIds = this.editvalues.selectedGeneralTableIds;
      // SLRvalue.selectedAssayTableIds = this.editvalues.selectedAssayTableIds;
      // SLRvalue.selectedValidationTableIds = this.editvalues.selectedValidationTableIds;
      SLRvalue.tableParamValue = this.editvalues.tableParamValue;
      SLRvalue.file_path = this.editvalues.file_path;
      SLRvalue.files = this.editvalues.files;
      SLRvalue.analyteName = this.editvalues.analyteName;
      SLRvalue.thresholdLayout = this.editvalues.thresholdLayout;
      // SLRvalue.selectedGeneralTableName=this.editvalues.selectedGeneralTableName;
      // SLRvalue.selectedAssayTableName=this.editvalues.selectedAssayTableName;
      // SLRvalue.selectedValidationTableName=this.editvalues.selectedValidationTableName;
      for (let i = 1; i < this.editvalues.analyteNames.length; i++) {
        this.analyteNames.push(this.addAnalyteRows());
      }
      this.analysisFormGroup.setValue(SLRvalue);
    }

  }


  get formControls() {
    return this.analysisFormGroup.controls;
  }


  formatDateForSending(date: Date): string {
    return this.datePipe.transform(date, 'yyyy-MM-dd') || '';
  }

  onSubmit(post: any) {
    const formattedDate = this.formatDateForSending(this.analysisFormGroup.controls.sampleDate.value);

    // tslint:disable-next-line: max-line-length

    this.analysisDetails = Object.assign({}, this.analysisFormGroup.value);
    this.analysisDetails.sampleDate = formattedDate
    this.analysisDetails.userId = this.storageService.get('username');

    // if (this.analysisDetails.multipleAnalytes === 'Yes') {
    //   this.analysisDetails.multipleAnalytes = 'True'
    // } else {
    //   this.analysisDetails.multipleAnalytes = 'False'
    // }

    this.analysisDetails.analysisId = (new Date).getTime().toString();
    // this.analysisDetails.selectedValidationTableIds = this.selectedValTables.map(res =>
    //   res = res.table_id);
    // this.analysisDetails.selectedAssayTableIds = this.selectedAssayTables.map(res =>
    //   res = res.table_id);
    // this.analysisDetails.selectedGeneralTableIds = this.selectedGeneralTables.map(res =>
    //   res = res.table_id);
    // this.analysisDetails.selectedAssayTableName=this.selectedAssayTables.map(res =>
    //  res =res.table_name );
    //  this.analysisDetails.selectedGeneralTableName=this.selectedGeneralTables.map( res =>
    //    res=res.table_name);
    //  this.analysisDetails.selectedValidationTableName=this.selectedValTables.map( res =>
    //  res =res.table_name);

    this.analysisDetails.analyteName = this.analyteNames.value.map(res =>
      res = res.analyteName);
    this.analysisDetails.lloq = this.analyteNames.value.map(res =>
      res = res.LLOQValue)
    this.analysisDetails.uloq = this.analyteNames.value.map(res =>
      res = res.ULOQValue)
    this.post = post;
    if (this.tableNames?.[0]?.parameter.parameter_value !== this.tableParamValue) {

      if (this.analysistype == 'SMR') {
        if (this.tableParamValue = []) {

          this.analysisDetails.tableParamValue = this.Smparameterlist;
        }
        else {
          this.analysisDetails.tableParamValue = this.NewSmparameterlist;
        }

      }
      else {
        if (this.tableParamValue = []) {

          this.analysisDetails.tableParamValue = this.Lmparameterlist;
        }
        else {
          this.analysisDetails.tableParamValue = this.Lmparameterlist;
        }
      }


    }

    this.formvalue.emit(this.analysisDetails);


  }

  addAnalyteRows() {
    return this.formBuilder.group({
      analyteName: ['', [Validators.pattern('^[A-Za-z\x270-9 .,-]+$')]],
      LLOQValue: ['', [Validators.pattern(this.decimalNumberPattern)]],
      ULOQValue: ['', [Validators.pattern(this.decimalNumberPattern)]],
    })
  }


  getData() {

    const analysis_type = this.analysistype;
    if (this.editvalues !== undefined) {

      const analysis_subtype = this.editvalues.analysisSubtype;

      this.templateService.getTableData().subscribe((table_names: []) => {
        try {
         
          this.tableNames = table_names;
          this.tableNames = Object.entries(this.tableNames).map((e) => ({ [e[0]]: e[1] })) ?? [];

          this.tableNames[0]?.parameter.forEach((res, index) => {
            if (res.analysis_type == 'SMR') {
              this.Smparameterlist.push(this.tableNames[0].parameter[index]);
              this.Smallvaluechanges(this.editvalues.tableParamValue);

            }
            else {
              this.Lmparameterlist.push(this.tableNames[0].parameter[index]);
              this.Largevaluechanges(this.editvalues.tableParamValue);

            }
          })


          this.tableNames[1]?.table_data.forEach((res, index) => {
            if (res.table_type == 'gen') {
              this.GenTablesList.push(this.tableNames[1].table_data[index]);

            }
            else if (res.table_type == 'mv') {
              this.ValTablesList.push(this.tableNames[1].table_data[index]);

            }
            else if (res.table_type == 'sa') {
              this.SumTablesList.push(this.tableNames[1].table_data[index]);
            }
          })
          this.GenTablesList = [...[{ table_name: 'Select All', table_type: 'Select All', checked: false }], ...this.GenTablesList];
          this.ValTablesList = [...[{ table_name: 'Select All', table_type: 'Select All', checked: false }], ...this.ValTablesList];
          this.SumTablesList = [...[{ table_name: 'Select All', table_type: 'Select All', checked: false }], ...this.SumTablesList];
          // this.SelectedGeneralTables(this.editvalues.selectedGeneralTableIds);
          // this.selectedSampleTables(this.editvalues.selectedAssayTableIds);
          // this.selectedValidTables(this.editvalues.selectedValidationTableIds);

        }
        catch (error) {
          console.log(error);
          this.tempdataerrorvalue=true;
          this.tempdataerror='Internal Servor error, Cannot get threshold values.Please refresh the page';
        }

      });
    }
    else {
      const analysis_subtype = this.analysisFormGroup.value?.analysisSubtype;


      this.templateService.getTableData().subscribe((table_names: any) => {
        try {
         console.log(table_names)
          this.tableNames = table_names;

          this.tableNames = Object.entries(this.tableNames).map((e) => ({ [e[0]]: e[1] }));

          this.tableNames[0]?.parameter.forEach((res, index) => {
            if (res.analysis_type == 'SMR') {
              this.Smparameterlist.push(this.tableNames[0].parameter[index])

            }
            else {
              this.Lmparameterlist.push(this.tableNames[0].parameter[index])

            }
          })


          this.tableNames[1]?.table_data.forEach((res, index) => {
            if (res.table_type == 'gen') {
              this.GenTablesList.push(this.tableNames[1].table_data[index]);

            }
            else if (res.table_type == 'mv') {
              this.ValTablesList.push(this.tableNames[1].table_data[index]);

            }
            else if (res.table_type == 'sa') {
              this.SumTablesList.push(this.tableNames[1].table_data[index]);
            }
          })
          this.GenTablesList = [...[{ table_name: 'Select All', table_type: 'Select All', checked: false }], ...this.GenTablesList];
          this.ValTablesList = [...[{ table_name: 'Select All', table_type: 'Select All', checked: false }], ...this.ValTablesList];
          this.SumTablesList = [...[{ table_name: 'Select All', table_type: 'Select All', checked: false }], ...this.SumTablesList];


        }
        catch (error) {
          console.log(error);
          this.tempdataerrorvalue=true;
          this.tempdataerror='Internal Servor error, Cannot get threshold values.Please refresh the page';
        }
      });
    }
  }

  Smallvaluechange(event, index) {
    this.tableParamValue = []
    this.tableParamValue = event?.target?.value;
    this.Smparameterlist[index].parameter_value = this.tableParamValue;
    const result = this.Smparameterlist.map(({ analysis_type, parameter_label, ...data }) => ({ ...data }));
    this.NewSmparameterlist = result;


  }

  Smallvaluechanges(event) {

    for (let i = 0; i < this.Smparameterlist.length; i++) {
      this.Smparameterlist[i].parameter_value = this.editvalues?.tableParamValue[i]?.parameter_value;
    }
  }

  Largevaluechanges(event) {

    for (let i = 0; i < this.Lmparameterlist.length; i++) {
      this.Lmparameterlist[i].parameter_value = this.editvalues?.tableParamValue[i]?.parameter_value;
    }
  }
  Largevaluechange(event, index) {
    this.tableParamValue = []
    this.tableParamValue = event?.target?.value;
    this.Lmparameterlist[index].parameter_value = this.tableParamValue;
    const result = this.Lmparameterlist.map(({ analysis_type, parameter_label, ...data }) => ({ ...data }));
    this.NewLmparameterlist = result;

  }


  // SelectedGeneralTables(event) {

  //   if (this.editvalues !== undefined) {

  //     for (let i = 1; i < this.GenTablesList.length; i++) {
  //       for (let j = 0; j < event.length; j++) {
  //         if (this.GenTablesList[i].table_id == event[j]) {
  //           this.GenTablesList[i].checked = true
  //         }
  //       }

  //     }
  //     if (event.option?.value === 'Select All') {
  //       this.GenTablesList.forEach((v) => {
  //         if (!event.option.selected) {
  //           v.checked = false
  //         } else {
  //           v.checked = true;
  //         }

  //       })
  //     }
  //     else {
  //       this.GenTablesList.forEach((v) => {
  //         if (event.option?.value === v.table_name && v.checked === true) {
  //           v.checked = false;
  //           this.GenTablesList[0].checked = false;

  //         } else if (event.option?.value === v.table_name) {
  //           v.checked = true;

  //         }
  //       })

  //     }
  //     const data = this.GenTablesList.filter((item) => {
  //       if (item.checked === true && item.table_name !== 'Select All') {
  //         return (item.table_name);
  //       }
  //     })
  //  
  //     this.selectedGeneralTables = []
  //     this.selectedGeneralTables = data;


  //   }
  //   else {
  //     if (event.option.value === 'Select All') {
  //       this.GenTablesList.forEach((v) => {
  //         if (!event.option.selected) {
  //           v.checked = false
  //         } else {
  //           v.checked = true;
  //         }

  //       })
  //     } else {
  //       this.GenTablesList.forEach((v) => {
  //         if (event.option.value === v.table_name && v.checked === true) {
  //           v.checked = false;
  //           this.GenTablesList[0].checked = false;

  //         } else if (event.option.value === v.table_name) {
  //           v.checked = true;

  //         }
  //       })

  //     }
  //     const data = this.GenTablesList.filter((item) => {
  //       if (item.checked === true && item.table_name !== 'Select All') {
  //         return (item.table_name);
  //       }
  //     })
  //     console.log(data)
  //     this.selectedGeneralTables = []
  //     this.selectedGeneralTables = data;
  //   }



  // }

  // selectedValidTables(event) {
  //   console.log(event, "passed value");
  //   if (this.editvalues !== undefined) {

  //     for (let i = 1; i < this.ValTablesList.length; i++) {
  //       for (let j = 0; j < event.length; j++) {
  //         // console.log(this.GenTablesList[i].table_name,event[j])
  //         if (this.ValTablesList[i].table_id == event[j]) {
  //           this.ValTablesList[i].checked = true
  //         }
  //       }

  //     }
  //     if (event.option?.value === 'Select All') {
  //       this.ValTablesList.forEach((v) => {
  //         if (!event.option.selected) {
  //           v.checked = false
  //         } else {
  //           v.checked = true;
  //         }

  //       })
  //     }
  //     else {
  //       this.ValTablesList.forEach((v) => {
  //         if (event.option?.value === v.table_name && v.checked === true) {
  //           v.checked = false;
  //           this.ValTablesList[0].checked = false;
  //         } else if (event.option?.value === v.table_name) {
  //           v.checked = true;

  //         }
  //       })
  //     }
  //     const data = this.ValTablesList.filter((item) => {
  //       if (item.checked === true && item.table_name !== 'Select All') {
  //         return (item.table_name);
  //       }
  //     })
  //     console.log(data)
  //     this.selectedValTables = []
  //     this.selectedValTables = data;

  //   }
  //   else {
  //     if (event.option.value === 'Select All') {
  //       this.ValTablesList.forEach((v) => {
  //         if (!event.option.selected) {
  //           v.checked = false
  //         } else {
  //           v.checked = true;
  //         }

  //       })
  //     }
  //     else {
  //       this.ValTablesList.forEach((v) => {
  //         if (event.option.value === v.table_name && v.checked === true) {
  //           v.checked = false;
  //           this.ValTablesList[0].checked = false;
  //         } else if (event.option.value === v.table_name) {
  //           v.checked = true;

  //         }
  //       })
  //     }
  //   }
  //   const data = this.ValTablesList.filter((item) => {
  //     if (item.checked === true && item.table_name !== 'Select All') {
  //       return (item.table_name);
  //     }
  //   })
  //   console.log(data)
  //   this.selectedValTables = []
  //   this.selectedValTables = data;
  // }

  // selectedSampleTables(event) {
  //   console.log(event, "passed value");
  //   if (this.editvalues !== undefined) {

  //     for (let i = 1; i < this.SumTablesList.length; i++) {
  //       for (let j = 0; j < event.length; j++) {
  //         // console.log(this.GenTablesList[i].table_name,event[j])
  //         if (this.SumTablesList[i].table_id == event[j]) {
  //           this.SumTablesList[i].checked = true
  //         }
  //       }

  //     }
  //     if (event.option?.value === 'Select All') {
  //       this.SumTablesList.forEach((v) => {
  //         if (!event.option.selected) {
  //           v.checked = false
  //         } else {
  //           v.checked = true;
  //         }
  //       })
  //     }
  //     else {
  //       this.SumTablesList.forEach((v) => {
  //         if (event.option?.value === v.table_name && v.checked === true) {
  //           v.checked = false;
  //           this.SumTablesList[0].checked = false;
  //         } else if (event.option?.value === v.table_name) {
  //           v.checked = true;

  //         }
  //       })
  //     }
  //     const data = this.SumTablesList.filter((item) => {
  //       if (item.checked === true && item.table_name !== 'Select All') {
  //         return (item.table_name);
  //       }
  //     })
  //     console.log(data)
  //     this.selectedAssayTables = []
  //     this.selectedAssayTables = data;

  //   }
  //   else {
  //     if (event.option.value === 'Select All') {
  //       this.SumTablesList.forEach((v) => {
  //         if (!event.option.selected) {
  //           v.checked = false
  //         } else {
  //           v.checked = true;
  //         }
  //       })
  //     }
  //     else {
  //       this.SumTablesList.forEach((v) => {
  //         if (event.option?.value === v.table_name && v.checked === true) {
  //           v.checked = false;
  //           this.SumTablesList[0].checked = false;
  //         } else if (event.option?.value === v.table_name) {
  //           v.checked = true;

  //         }
  //       })
  //     }
  //   }
  //   const data = this.SumTablesList.filter((item) => {
  //     if (item.checked === true && item.table_name !== 'Select All') {
  //       return (item.table_name);
  //     }
  //   })
  //   console.log(data)
  //   this.selectedAssayTables = []
  //   this.selectedAssayTables = data;
  // }


  getDropDownValues(key) {
    return this.dropDownService.dropDownsMap.get(key);
  }

  get analyteNames() {
    return this.analysisFormGroup.get('analyteNames') as FormArray;
  }

  addAnalyte() {
    this.analyteNames.push(this.addAnalyteRows());

  }

  removeAnalyte(index) {
    this.analyteNames.removeAt(index);
  }

  resetAnalysis() {

  }

  multipleAnalyteValues() {
    const multipleChecked = this.analysisFormGroup.value.multipleAnalytes;
    const count = this.analysisFormGroup.value.analyteNames;

    if (multipleChecked === 'True' && count.length === 1) {

      // tslint:disable-next-line: no-unused-expression
      this.analysisFormGroup.invalid;
    } else {

    }

  }

}
