export const environment = {
    production: false,
    serverUrl: 'https://zegmbwqti5.execute-api.us-east-2.amazonaws.com/dev',
    bucket: 'redthreadaudit-test-upload',
    userPool: {
      UserPoolId: 'us-east-2_NP0cDl5KU',
      ClientId: '37umkq5u5kso0vhs5a3o0lqdnl',
      region: 'us-east-2'
    },
    identityPoolId: 'us-east-2:2360215c-c36d-4a0e-abc6-a05cdbb5a4c6',
    buckets: {
      'us-east-2': 'redthreadaudit-test-upload'
    },
    modules: {
      key: 'analysisType',
      values: [
          {
              'name': 'SMR',
              'desc': 'Small Molecule Report'
          },
          {
              'name': 'LMR',
              'desc': 'Large Molecule Report'
          },
          {
            'name': 'qPCR',
            'desc': 'QPCR Molecule Report'
          }
      ]
  },
    defaultRegion: 'us-east-2',
    cognito: {
      identityPoolId: 'us-east-2:2360215c-c36d-4a0e-abc6-a05cdbb5a4c6', // REQUIRED - Amazon Cognito Identity Pool ID
      region: 'us-east-2', // REQUIRED - Amazon Cognito Region
      userPoolId: 'us-east-2_NP0cDl5KU', // OPTIONAL - Amazon Cognito User Pool ID
      userPoolWebClientId: '37umkq5u5kso0vhs5a3o0lqdnl', // OPTIONAL - Amazon Cognito Web Client ID
    },
    Storage: {
      AWSS3: {
        bucket: 'redthreadaudit-test-upload', // REQUIRED -  Amazon S3 bucket
        region: 'us-east-2', // OPTIONAL -  Amazon service region
      }
    }
  };
  const awsconfig = {
    'aws_project_region': 'us-east-2',
    'aws_cognito_region': 'us-east-2',
    'aws_user_pools_id': 'us-east-2_NP0cDl5KU',
    'aws_user_pools_web_client_id': '37umkq5u5kso0vhs5a3o0lqdnl',
    'aws_cognito_mfa_configuration': 'OPTIONAL',
  
  };
  export default awsconfig;